<template>
	<div class="home" :class="show?'ish5':''">
		<div class="htop">
		<mtop />
		</div>
		<div ref="mySwiper" class="swiper-container banner" id="banner"  >
			
			<div class="swiper-wrapper">
				<div class="swiper-slide">
					<img src="@/assets/ximg/banner-0.png" alt=""  @click="toNews"/>
				</div>
				<div class="swiper-slide">
					<img src="@/assets/ximg/banner-wky@2x.png" alt="" @click="toOpen('http://wkz.xingrunkeji.cn')" />
				</div>
				<div class="swiper-slide">
					<img src="@/assets/images/banner1.png" alt=""  />
				</div>
				<div class="swiper-slide">
					<img src="@/assets/images/banner2.png" alt="" />
				</div>
			</div>
		</div>

		<!-- 产品及服务项目 -->
		<div class="content">
			<div class="box">
				<div class="btop">
				<div class="title">产品及服务项目</div>
				<div class="tips">多行业、多场景、多类型产品开发，为企业提供适合的技术开发解决方案</div>
				</div>
			<div class="cont">
				<div class="card">
					<div class="header">
						<img src="@/assets/ximg/1-nft@2x.png" alt="" class="ico">
						<div class="title">NFT数藏系统</div>						
					</div>
					<div class="cbox">
						<span>提供数字藏品IP、铸造、营销、交易、版权、安全、资质的一站式全套解决方案</span>
						<div class="btn fadeIn" @click="toPage('/collection')">立即了解</div>
					</div>
				</div>

				<div class="card">
					<div class="header">
						<img src="@/assets/ximg/2-szqb@2x.png" alt="" class="ico">
						<div class="title">数字钱包</div>							
					</div>
					<div class="cbox">
					 <span>	多链去中心化数字资产钱包,支持多签、多链、多语言、跨链、聚合交易、DAPP应用商店、区块链浏览器等核心功能
					</span>
					<div class="btn fadeIn" @click="toPage('/digital')">立即了解</div>
					</div>
				</div>

				<div class="card">
					<div class="header">
						<img src="@/assets/ximg/3-lhjqr@2x.png" alt="" class="ico">
						<div class="title">边缘云计算平台</div>							
					</div>
					<div class="cbox">
						<span>
						通过利用海量碎片化闲置资源构建低成本高品质云服务，比如云游戏/直播/下载/视频加速。
						用户可以通过路由器、智能硬件、闲置电脑计入到微客赚平台获得现金收益。
					</span>
						<div class="btn fadeIn" @click="toPage('/computing')">立即了解</div>
					</div>
				</div>
				<div class="card">
					<div class="header">
						<img src="@/assets/ximg/4-qylg@2x.png" alt="" class="ico">
						<div class="title">企业链改</div>							
					</div>
					<div class="cbox">
						<span>
						基于区块链技术结合企业实际应用场景，帮助企业实现数字资产上链，资产数字化,解决中心化互联网的信息不透明、数据隐私、资产安全等问题
					</span>
					<div class="btn fadeIn" @click="toPage('/chainReform')">立即了解</div>
					</div>
				</div>
				<div class="card">
					<div class="header">
						<img src="@/assets/ximg/5-yyzkf@2x.png" alt="" class="ico">
						<div class="title">元宇宙开发</div>							
					</div>
					<div class="cbox">
						结合数字孪生、VR、区块链底层实现虚拟现实世界完美融合,帮助品牌企业打造各种场景化的营销新玩法
					</div>
				</div>

				<div class="card">
					<div class="header">
						<img src="@/assets/ximg/6-fxsc@2x.png" alt="" class="ico">
						<div class="title">电商系统</div>							
					</div>
					<div class="cbox">
						<span>
						微客赚电商电商系统系统,支持多商户、多门店、代理分销模式、多种支付方式、前端支持DIY自定义UI,支持小程序、app等入口
					</span>
					<div class="btn fadeIn" @click="toPage('/shoppingMall')">立即了解</div>
					
					</div>
				</div>

				<div class="card">
					<div class="header">
						<img src="@/assets/ximg/7-ai@2x.png" alt="" class="ico">
						<div class="title">AI聊天机器人</div>							
					</div>
					<div class="cbox">
						<span>
						基于Chat-GPT构架的大语言模型，可以模仿人类的语言处理，与人进行自然对话交流，包括但不限于通用知识、科学、技术、人文等各种形式的回答
					</span>
					<div class="btn fadeIn" @click="toPage('/chatAi')">立即了解</div>					
					</div>
				</div>
				<div class="card">
					<div class="header">
						<img src="@/assets/ximg/8-app@2x.png" alt="" class="ico">
						<div class="title">各类APP定制化开发</div>							
					</div>
					<div class="cbox">
						<span>构建私有化全行业解决方案，全方位满足您的开发要求
					</span>
					<div class="btn fadeIn" @click="toPage('/save')">立即了解</div>						
				</div>
				</div>
			</div>
		</div>
</div>
	<!-- 客户案例 -->
	<div class="content hbg">
		<div class="box ">
			<div class="btop">
				<div class="title">客户案例</div>
				<div class="tips">1000+各行业客户积累、甄选优质开发案例</div>
			</div>
			<div class="case">
			<div class="case-tab">
				<div ref="myCase" class="swiper-container Gwidth" id="case">
					<div class="swiper-wrapper">
						<div class="swiper-slide">
							<p
								class="case-nav"
								:class="{ active: current == 0 }"
								@click="(current = 0), getCase()"
							>
								精品推荐
							</p>
						</div>
						<div
							class="swiper-slide"
							v-for="(item, index) in caseNav"
							:key="item.id"
						>
							<p
								class="case-nav"
								:class="{ active: current == index + 1 }"
								@click="(current = index + 1), getCase()"
							>
								{{ item.name }}
							</p>
						</div>
					</div>
				</div>
			</div>
			<div class="case-content Gwidth">
				<div
					class="case-content-item"
					v-for="item in caseList"
					:key="item.id"
				>
					<router-link
						:to="{
							name: 'gallerypc',
							params: {
								anliid: item.id,
							},
						}"
					>
						<div class="case-content-item-img">
							<img :src="item.thumb" :alt="item.title" />
						</div>
						<div class="case-content-item-cont">
							<div class="case-content-item-cont-title">
								{{ item.title }}
							</div>
							<div class="case-content-item-cont-des">
								{{ item.description }}
							</div>
							<div class="case-content-item-cont-tags">
								<span
									v-for="(iitem, index) in item.label"
									:key="index"
									>{{ iitem }}</span
								>
							</div>
						</div>
					</router-link>
				</div>
			</div>

			<div class="case-more">
				<router-link to="/gallery">更多精彩案例</router-link>
			</div>
		</div>
		</div>
	</div>
	<!--  为什么选择我们 -->
	<div class="content abg">
		<div class="box">
			<div class="btop">
				<div class="title">为什么选择我们</div>
				<div class="tips">专注技术开发、十年品牌沉淀、打造实力口碑</div>
			</div>
			<div class="cont">
				<div class="card2">
					<div class="header">
						<img src="@/assets/ximg/icon-team@2x.png" alt="" class="ico">
						<div class="title">强大的研发团队</div>							
					</div>
					<div class="cbox">
						项目经验积累多、技术团队100+、项目案例1000+、软著12+
					</div>
				</div>
				<div class="card2">
					<div class="header">
						<img src="@/assets/ximg/icon-liucheng@2x.png" alt="" class="ico">
						<div class="title">标准化实施流程</div>							
					</div>
					<div class="cbox">
						产品沟通设计>UI沟通设计>软件开发>项目测试>项目验收>项目交付
					</div>
				</div>
				<div class="card2">
					<div class="header">
						<img src="@/assets/ximg/icon-jiage@2x.png" alt="" class="ico">
						<div class="title">比同行价格优惠</div>							
					</div>
					<div class="cbox">
						已开发过全行业的成熟案例价格有竞争力，工期短，成本低，上线快
					</div>
				</div>
			<!-- </div>
			<div class="cont"> -->
				<div class="card2">
					<div class="header">
						<img src="@/assets/ximg/icon-kaifa@2x.png" alt="" class="ico">
						<div class="title">丰富的开发经验</div>							
					</div>
					<div class="cbox">
						星润历时9年自主研发XingMall商城底层，助力企业快速搭建私域电商系统
					</div>
				</div>
				<div class="card2">
					<div class="header">
						<img src="@/assets/ximg/icon-jindu@2x.png" alt="" class="ico">
						<div class="title">开发进度了如指掌</div>							
					</div>
					<div class="cbox">
						项目原型系统、进度管控系统、工单系统、让项目无缝对接
					</div>
				</div>
				<div class="card2">
					<div class="header">
						<img src="@/assets/ximg/icon-shouhou@2x.png" alt="" class="ico">
						<div class="title">完善的服务体系</div>							
					</div>
					<div class="cbox">
						全方位服务支持，售前、售中、售后跟踪服务，确保及时为客户解决问题
					</div>
				</div>
			</div>
		</div>
	</div>

		<!-- 合作伙伴 -->
		<div class="content hzbg">

		<div class="partner">
			<div class="Gtitle">合作伙伴</div>
			<div class="partner-cont Gwidth">
				<div class="img">
					<img src="@/assets/images/gogo1.jpg" alt="" />
				</div>
				<div class="img">
					<img src="@/assets/images/gogo2.jpg" alt="" />
				</div>
				<div class="img">
					<img src="@/assets/images/gogo3.jpg" alt="" />
				</div>
				<div class="img">
					<img src="@/assets/images/gogo4.jpg" alt="" />
				</div>
				<div class="img">
					<img src="@/assets/images/gogo5.jpg" alt="" />
				</div>
				<div class="img">
					<img src="@/assets/images/gogo6.jpg" alt="" />
				</div>
				<div class="img">
					<img src="@/assets/images/gogo7.jpg" alt="" />
				</div>
				<div class="img">
					<img src="@/assets/images/gogo8.jpg" alt="" />
				</div>
				<div class="img">
					<img src="@/assets/images/gogo9.jpg" alt="" />
				</div>
				<div class="img">
					<img src="@/assets/images/gogo10.jpg" alt="" />
				</div>
				<div class="img">
					<img src="@/assets/images/gogo11.jpg" alt="" />
				</div>
				<div class="img">
					<img src="@/assets/images/gogo12.jpg" alt="" />
				</div>
			</div>
		</div>

		<div class="box">
			<div class="btop">
				<div class="title">客户评价</div>
				<div class="tips">金杯银杯不如客户的口碑</div>
			</div>
			<div class="cont">				
				<div class="sollcor">
					<div class="left" @click="toChange(1)">
					<img src="@/assets/ximg/icon-left@2x.png" alt="" />
				</div>
				<div class="slide fadeInLeft" v-if="page==1">
				<div class="card3">
					<div class="cbox">
						<div class="cico"><img src="@/assets/ximg/KHPJ-1@2x.png" alt="" /></div>
						<div class="txt">星润时代科技定制化开发公司以其深度的行业洞察力和创造性的解决方案，为客户提供了度身定制的科技解决方案，满足了各种需求和挑战</div>
					</div>
					<div class="cbotttom">
						—— 海诺泰
					</div>						
				</div>
				<div class="card3 m24">
					<div class="cbox">
						<div class="cico"><img src="@/assets/ximg/KHPJ-2@2x.png" alt="" /></div>
						<div class="txt">这家科技定制化开发公司不仅在技术领域具备卓越的实力，还深谙客户需求，为客户提供了可靠、创新和度身定制的科技解决方案，持续推动业务成功。</div>
					</div>
					<div class="cbotttom">
						—— NEXT
					</div>						
				</div>
				<div class="card3">
					<div class="cbox">
						<div class="cico"><img src="@/assets/ximg/KHPJ-3@2x.png" alt="" /></div>
						<div class="txt">他们的专业定制化开发服务不仅为客户解决了独特的技术挑战，还在市场上树立了可信度和卓越的口碑，是值得依赖的合作伙伴。</div>
					</div>
					<div class="cbotttom">
						—— Hermit Wallet
					</div>						
				</div>
				</div>
				<div class="slide fadeInRight" v-if="page==2">
				<div class="card3">
					<div class="cbox">
						<div class="cico"><img src="@/assets/ximg/logo-PEGO.AI@2x.png" alt="" /></div>
						<div class="txt">星润时代科技定制化开发公司以其深度的行业洞察力和创造性的解决方案，为客户提供了度身定制的科技解决方案，满足了各种需求和挑战</div>
					</div>
					<div class="cbotttom">
						—— PEGO.AI
					</div>						
				</div>
				<div class="card3 m24">
					<div class="cbox">
						<div class="cico"><img src="@/assets/ximg/logo-Octopus Finance@2x.png" alt="" /></div>
						<div class="txt">星润科技是我们在科技领域的首选合作伙伴，他们不仅满足了客户的当前需求，还在未来的发展中持续提供创新和支持，使我们能够取得长期的成功和增长。</div>
					</div>
					<div class="cbotttom">
						—— Octopus Finance
					</div>						
				</div>
				
				<!-- <div class="card3 ">
					<div class="cbox">
						<div class="cico"><img src="@/assets/ximg/KHPJ-1@2x.png" alt="" /></div>
						<div class="txt">星润时代科技定制化开发公司以其深度的行业洞察力和创造性的解决方案，为客户提供了度身定制的科技解决方案，满足了各种需求和挑战</div>
					</div>
					<div class="cbotttom">
						—— 海诺泰
					</div>						
				</div> -->
			</div>
				<div class="right" @click="toChange(2)">
					<img src="@/assets/ximg/icon-right@2x.png" alt="" />
				</div>
				</div>
			
			</div>
		</div>
	</div>
		<mbottom />
	</div>
</template>

<script>
	import Swiper from "swiper";
	import "swiper/swiper.css";
	import mtop from "../components/top.vue";
	import mbottom from "../components/bottom.vue";
	export default {
		name: "HomeView",
		components: {
			mtop,
			mbottom,
		},
		data() {
			return {
				show:false,
				current: 0,
				caseNav: [],
				caseList: [],
				newsList: [],
				fqaList: [],
				page:1
			};
		},
		mounted() {
			this.initSwiper();
			this.getCaseNav();
			this.getNews();
			this.getarticleList();
			    if (this._isMobile()) {
				this.show=true;
				} else {
				this.show=this.$store.getters.phone;
				}  
	},
		  watch: {
      '$store.getters.phone': function (val) {
      this.show = val
    },
    deep: true,
    immediate: true,
   },
		computed: {
			getHot() {
				if (this.newsList.length > 0) {
					return this.newsList[0];
				} else {
					return [];
				}
			},
			getHotItem() {
				if (this.newsList.length > 2) {
					return this.newsList[1];
				} else {
					return [];
				}
			},
			getNewsList() {
				if (this.newsList.length > 3) {
					return this.newsList.filter((item, index) => {
						if (index > 1) {
							return item;
						}
					});
				} else {
					return [];
				}
			},
		},
	methods: {
			      _isMobile() {
   let flag = navigator.userAgent.match(/(phone|pod|iPhone|iPod|ios|Android|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
 return flag;   
},
toChange(e){
	this.page=e
},
toNews(){
	sessionStorage.setItem("conid", 19);
	this.toPage('/teamdetal')
},
toOpen(e){
	window.open(e)
},
toPage(e){
	this.$router.replace({ path: e });
},
			//海量客户案例
			async getCaseNav() {
				try {
					let {
						data: {
							code,
							data: { list },
						},
					} = await this.$http.get(this.$api.projectCate);
					// console.log(code, list);
					this.caseNav = list;
					this.$nextTick(() => {
						this.initCase();
					});
					this.getCase();
				} catch (error) {}
			},
			async getCase() {
				try {
					let {
						data: {
							code,
							data: {
								list: { data },
							},
						},
					} = await this.$http.get(this.$api.casusList, {
						is_hot: this.current ? 2 : 1,
						project_cate_id: this.current
							? this.caseNav[this.current - 1].id
							: "",
						page: 1,
						page_size: 3,
					});
					// console.log(data);
					this.caseList = data;
				} catch (error) {
					console.log(error);
				}
			},
			//新闻
			async getNews() {
				try {
					let {
						data: {
							code,
							data: {
								list: { new_list: data },
							},
						},
					} = await this.$http.get(this.$api.newsCenter);
					this.newsList = data;
				} catch (error) {}
			},
			async getarticleList() {
				try {
					let {
						data: {
							code,
							data: {
								list: { data },
							},
						},
					} = await this.$http.get(this.$api.articleList, {
						type: 2,
					});
					// console.log(data);
					this.fqaList = data;
				} catch (error) {}
			},
			initSwiper() {
				// eslint-disable-next-line
				let vueComponent = this; //获取vue组件实例
				//一个页面有多个swiper实例时，为了不互相影响,绑定容器用不同值或变量绑定
				new Swiper("#banner", {
					loop: true, // 循环模式选项
					autoHeight: "true", //开启自适应高度,容器高度由slide高度决定
					//分页器
					pagination: {
						el: ".swiper-pagination",
						clickable: true, //分页器按钮可点击
					},
					//导航器
					autoplay: {
						//自动播放,不同版本配置方式不同
						delay: 5000,
						stopOnLastSlide: false,
						disableOnInteraction: false,
					},
				});
			},
			initCase() {
				//一个页面有多个swiper实例时，为了不互相影响,绑定容器用不同值或变量绑定
				new Swiper("#case", {
					loop: false,
					breakpoints: {
						320: {
							//当屏幕宽度大于等于320
							slidesPerView: 4,
						},
						750: {
							//当屏幕宽度大于等于768
							slidesPerView: 6,
						},
					},
				});
			},
		},
		filters: {
			formatDay(e) {
				if (e) {
					return e.substr(8, 2);
				}
			},
			formatYear(e) {
				if (e) {
					return e.substr(0, 7);
				}
			},
		},
	};
</script>
<style lang="scss" scoped>
	
	.home {
		background-color: #ffffff;
			.content{
				.box{
					width: 1200px;
					margin: 0 auto;
					padding: 86px 0;
					.btop{
						display: flex;
						align-items: center;
						flex-direction: column;
						margin: 0px auto 62px;
						.title{
							font-size: 32px;
							font-weight: 500;
							color: #1D2129;
							line-height: 45px;
							margin-bottom: 16px;
						}
						.tips{
							font-size: 16px;
							font-weight: 400;
							color: #646566;
							line-height: 22px;
						}
						

					}
					.cont{
						display: flex;
						justify-content: space-between;
						align-items: center;
						flex-wrap: wrap;
						margin: 32px 0;
					
						.card{
							width: 282px;
							height: 236px;
							background: linear-gradient(180deg, #F3F7FD 0%, #FFFFFF 100%);
							box-shadow: 0px 0px 20px 0px rgba(16,38,80,0.1);
							border-radius: 2px;
							border: 1px solid #EBEDF0;
							padding: 25px;
							box-sizing: border-box;
							margin-bottom: 32px;
							.header{
								display: flex;
								align-items: center;
								border-bottom: 1px solid #EBEDF0;
								padding: 0px 0 13px;
								margin-bottom: 13px;
								.ico{
									width: 36px;
							height: 36px;
								}
								.title{
									font-size: 18px;
								font-weight: 500;
								color: #1D2129;
								line-height: 25px;
								margin-left: 10px;
								}
							}
							.cbox{
								font-size: 14px;
								font-weight: 400;
								color: #646566;
								line-height: 20px;
								display: flex;
								align-items: center;
								justify-content: center;
								height: 114px;

								.btn{
									width: 100px;
									height: 38px;
									background: #FFFFFF;
									box-shadow: 0px 5px 20px 0px rgba(12,65,189,0.1);
									border-radius: 2px;
									border: 1px solid #2B6BFF;
									font-size: 14px;
									font-family: PingFangSC-Medium, PingFang SC;
									font-weight: 500;
									color: #2B6BFF;
									line-height: 20px;
									text-shadow: 0px 5px 20px rgba(12,65,189,0.1);
									display: flex;
									align-items: center;
									justify-content: center;
									display: none;
									cursor: pointer;
								}
							}
							.cbox:hover{
								span{
									display: none;
								}
								.btn{
									display: flex;
									transition: all 0.3s;
								}
							}
						}	
						.card2{
							width: 373px;
							height: 113px;
							background: rgba(255,255,255,0.2);
							border-radius: 6px;
							border: 2px solid #FFFFFF;
							backdrop-filter: blur(4px);
							padding: 20px 30px;
							box-sizing: border-box;
							margin-bottom: 32px;
							.header{
								display: flex;
								align-items: center;
								margin-bottom: 12px;
								.ico{
									width: 26px;
							height: 26px;
								}
								.title{
									font-size: 18px;
								font-weight: 500;
								color: #1D2129;
								line-height: 25px;
								margin-left: 6px;
								}
							}
							.cbox{
								font-size: 14px;
								font-weight: 400;
								color: #646566;
								line-height: 20px;
							}
						}	
						.sollcor{
							display: flex;
							align-items: center;
							justify-content: space-between;
							position: relative;
							// overflow-x: auto;
							.left,.right{
							width: 28px;
							height: 28px;
							border-radius: 100%;
							position: absolute;
							z-index: 99;
							img{
								width: 100%;
								height: 100%;
								border-radius: 100%;
								cursor: pointer;
							}
						}
						.left{
							left: -45px;
						}
						.right{
							right: -45px;
						}
						}
						.slide{
							width: 100%;
							overflow: auto;
							display: flex;
							align-items: center;
							min-width: 1200px;
						}
						.card3{
							width: 384px;
							min-width:  384px;
							height: 166px;
							background: #FFFFFF;
							box-shadow: 0px 0px 20px 0px rgba(16,38,80,0.1);
							border-radius: 2px;
							padding: 32px;
							box-sizing: border-box;
							.cbox{
								display: flex;
								align-items: flex-start;
								.cico{
									width: 32px;
									height: 32px;
									background: #FFFFFF;
									border-radius: 2px;
									// border: 1px solid #EBEDF0;
									img{
										width: 100%;
										height: 100%;
									}
								}
								.txt{
									flex: 1;
									font-size: 14px;
									font-weight: 400;
									color: #646566;
									line-height: 20px;
									margin-left: 16px;
									min-height: 80px;
									margin-top: 2px;
								}
							}
							.cbotttom{
								width: 100%;
								margin-top: 4px;
								text-align: right;
							}
						}
						.m24{
							margin: 0 24px;
						}
					}


				}
				

		}
		.hbg{
					background: #F5F6FA;
				}
		.abg{
			background: url(~@/assets/ximg/bj-wsmxzwm@2x.png) no-repeat;
			background-size: cover;
			background-position: center center;
		}
		.hzbg{
			background: url(~@/assets/ximg/background-parter@2x.png) no-repeat;
			background-size: cover;
			background-position: center center;
		}

	}

	.ish5{
		.content{
			.box{
			width: auto;
			padding: 32px;
				.btop{
					.title{
						font-size: 36px;					
					}
					.tips{
						font-size: 20px;
						line-height: 38px;
					}
				}
				.cont{
				.card{
					width: calc(50% - 16px);
					height: auto;
					.header{
						.title{
							font-size: 26px;
							white-space: nowrap;
						}
					}
					.cbox{
						height: 228px;
						font-size: 22px;
						line-height: 32px;
						.btn{
							padding: 12px;
						}
					}
				}

				.card2{
					width: calc(50% - 16px);
					height: 228px;
					.header{
						.title{
							font-size: 26px;
						}
					}
					.cbox{
						font-size: 22px;
						line-height: 38px;
					}
				}

				.sollcor{
					// overflow: auto;
					height: 100%;
					flex-direction: column;
					.left,.right{
						display: none;
					}
					.slide{
						flex-direction: column;
    					min-width: auto;
					}
					.card3{
						width: 680px;
						height: auto;
						margin: 12px ;
						.cbox{
							.txt{
								font-size: 26px;
								line-height: 32px;
							}
						}
					}
				}

			}

			}
		}
	}

	@media screen and (min-width: 0px) {
		.banner {
			font-size: 0;
			img {
				width: 100%;
			}
		}
		.product-nav {
			position: relative;
			z-index: 2;
			// margin-top: -30px;
			.Gwidth{
				margin: 20px auto;
			}

			li {
				box-shadow: 0px 2px 10px 0px rgba(29, 33, 41, 0.1);
				padding: 24px 50px 24px 90px;
				margin-bottom: 10px;
				position: relative;
				a {
					font-size: 28px;
					font-weight: 400;
					color: #969799;
					h4 {
						font-size: 32px;
						font-weight: 500;
						color: #1d2129;
						line-height: 25px;
						text-shadow: 0px 2px 10px rgba(29, 33, 41, 0.1);
						background: url(~@/assets/ico/icon-jiantou.png) right
							center no-repeat;
						background-size: 12px 12px;
						margin-bottom: 10px;
					}
					.more {
						position: absolute;
						opacity: 0;
						left: 0;
						bottom: 0;
						transition: 0.35s;
					}
				}
				&:nth-child(1) {
					background: #ffffff url(~@/assets/ico/icon-nft.png) 50px
						17px no-repeat;
					background-size: 38px 38px;
				}
				&:nth-child(2) {
					background: #ffffff url(~@/assets/ico/icon-yuanyuzhou.png)
						50px 17px no-repeat;
					background-size: 38px 38px;
				}
				&:nth-child(3) {
					background: #ffffff url(~@/assets/ico/icon-kaifa.png) 50px
						17px no-repeat;
					background-size: 38px 38px;
				}
			}
		}

		.Gtitle {
			font-size: 38px;
			font-weight: 500;
			color: #1d2129;
			text-align: center;
			margin:60px 0;
		}
		.case {
			padding: 20px 0;
			background-color: #f5f6fa;
			&-tab {
				display: flex;
				justify-content: center;
				border-bottom: 1px solid #dcdee0;
				.case-nav {
					text-align: center;
					font-size: 28px;
					font-weight: 400;
					color: #646566;
					padding: 10px 0;
					cursor: pointer;
					&.active {
						font-size: 28px;
						font-weight: 500;
						color: #2b6bff;
						border-bottom: 1px solid #2b6bff;
					}
				}
			}
			&-content {
				&-item {
					width: 100%;
					background: #ffffff;
					border-radius: 2px;
					margin-top: 20px;
					transition: 0.35s;
					&-img {
						font-size: 0;
						overflow: hidden;
						img {
							width: 100%;
							transition: 0.35s;
						}
					}
					&-cont {
						padding: 30px 30px 40px 30px;
						box-sizing: border-box;
						&-title {
							font-size: 28px;
							font-weight: 500;
							color: #1d2129;
						}
						&-des {
							font-size: 28px;
							line-height: 36px;
							font-weight: 400;
							color: #646566;
							margin-top: 8px;
						}
						&-tags {
							margin-top: 24px;
							span {
								padding: 4px 14px;
								display: inline-block;
								font-size: 26px;
								font-weight: 400;
								color: #2b6bff;
								// line-height: 20px;
								background: #f2f3f5;
								border-radius: 2px;
								margin: 0 8px 8px 0;
							}
						}
					}
				}
			}
			&-more {
				width: 180px;
				height: 40px;
				border-radius: 2px;
				border: 1px solid #2b6bff;
				text-align: center;
				margin: 50px auto 0 auto;
				a {
					font-size: 28px;
					font-weight: 400;
					color: #2b6bff;
					line-height: 40px;
				}
			}
		}
		.home-more {
			padding: 20px 0;
			&-content {
				display: flex;
				flex-wrap: wrap;
				&-item {
					transition: 0.35s;
					width: calc(50% - 14px);
					margin-right: 24px;
					background: #ffffff;
					box-shadow: 0px 0px 85px 0px rgba(29, 33, 41, 0.05);
					border-radius: 2px;
					border: 1px solid #ebedf0;
					padding: 20px;
					box-sizing: border-box;
					margin-bottom: 24px;
					display: flex;
					flex-direction: column;
					justify-content: center;
					&:nth-child(2n) {
						margin-right: 0;
					}
					&:last-child {
						align-items: center;
						.home-more-content-item-ico {
							img {
								width: 32px;
							}
						}
						h5 {
							font-weight: 400;
							color: #2b6bff;
						}
					}
					&-ico {
						img {
							width: 55px;
						}
					}
					h5 {
						font-size: 28px;
						font-weight: 600;
						color: #1d2129;
						line-height: 28px;
					}
					&-des {
						font-size: 28px;
						font-weight: 400;
						color: #646566;
						line-height: 34px;
						margin-top: 8px;
					}
				}
			}
		}
		.about-us {
			background: url(~@/assets/images/bj-wsmxzwm.png) center/cover
				no-repeat;
			padding: 20px 0;
			&-header {
				&-title {
					font-size: 38px;
					font-weight: 500;
					color: #1d2129;
				}
				&-des {
					font-size: 24px;
					font-weight: 400;
					color: #323233;
					margin-top: 5px;
				}
			}
			&-content {
				display: flex;
				flex-wrap: wrap;
				&-item {
					width: 33.33%;
					text-align: center;
					&-ico {
						font-size: 0;
						img {
							width: 100px;
						}
					}
					&-title {
						span {
							font-size: 28px;
							font-weight: 600;
							color: #1d2129;
							&:nth-child(1) {
								font-size: 30px;
							}
						}
					}
				}
			}
		}
		.partner {
			padding: 20px 0;			
			&-cont {
				display: flex;
				flex-wrap: wrap;
				position: relative;
				background: #fff;
				&::after {
					content: "";
					border: 1px solid #ebedf0;
					width: 200%;
					height: 200%;
					transform-origin: 0 0;
					transform: scale(0.5);
					position: absolute;
					left: 0;
					top: 0;
				}
				.img {
					width: 50%;
					font-size: 0;
					text-align: center;
					position: relative;
					z-index: 1;
					padding: 10px 0;
					&::after {
						content: "";
						border: 1px solid #ebedf0;
						width: 200%;
						height: 200%;
						transform-origin: 0 0;
						transform: scale(0.5);
						position: absolute;
						left: 0;
						top: 0;
						transition: 0.35s;
					}
					transition: 0.35s;
					img {
						max-width: 172px;
					}
				}
			}
		}
		.news {
			background: #f5f6fa;
			padding: 20px 0;
			&-content {
				&-head {
					display: flex;
					align-items: center;
					justify-content: space-between;
					font-weight: 400;
					color: #333333;
					font-size: 32px;
					border-bottom: 1px solid #dfdfdf;
					margin-bottom: 20px;
					&-left,
					&-right {
						line-height: 40px;
						display: none;
						padding: 0 5px;
					}
					&-left {
						display: block;
						border-bottom: 3px solid #2b6bff;
					}
				}
				&-cont {
					.news-hot {
						font-size: 0;
						position: relative;
						margin-bottom: 10px;
						img {
							width: 100%;
						}
						&-cont {
							background: rgba(#000000, 0.88);
							position: absolute;
							width: 100%;
							left: 0;
							bottom: 0;
							padding: 10px 20px;
							box-sizing: border-box;
							&-title {
								font-size: 28px;
								color: #ffffff;
							}
							&-des {
								font-size: 24px;
								color: #ffffff;
								white-space: nowrap;
								text-overflow: ellipsis;
								overflow: hidden;
							}
						}
					}
					.news-newlist {
						background: #ffffff;
						box-shadow: 0px 0px 2px 0px rgb(81 81 81 / 15%);
						box-sizing: border-box;
						padding: 16px 21px;
						margin-bottom: 10px;
						position: relative;
						&::after {
							content: "";
							width: 16px;
							height: 14px;
							background: url(~@/assets/ico/back.png) center
								center no-repeat;
							background-size: 16px 14px;
							position: absolute;
							right: 21px;
							bottom: 16px;
						}
						&-top {
							display: flex;
							&-left {
								margin-right: 15px;
								text-align: center;
								&-day {
									font-size: 38px;
									font-weight: 600;
									color: #333333;
								}
								&-year {
									font-size: 28px;
									font-weight: 400;
									color: #999999;
									white-space: nowrap;
									margin-top: 14px;
									position: relative;
									&::after {
										content: "";
										width: 20px;
										height: 2px;
										background-color: #333333;
										position: absolute;
										left: 50%;
										top: -10px;
										transform: translateX(-50%);
									}
								}
							}
							&-right {
								&-title {
									font-size: 32px;
									color: #333333;
									// white-space: nowrap;
									// text-overflow: ellipsis;
									// overflow: hidden;
								}
								&-des {
									margin-top: 10px;
									font-size: 28px;
									color: #666666;
									overflow: hidden;
									text-overflow: ellipsis;
									display: -webkit-box;
									-webkit-line-clamp: 2;
									-webkit-box-orient: vertical;
								}
							}
						}
						&-tags {
							font-size: 28px;
							font-weight: 400;
							color: #999999;
							margin-top: 10px;
							span {
								margin-right: 5px;
								margin-bottom: 5px;
							}
						}
					}
					.fqa {
						background: #ffffff;
						box-shadow: 0px 0px 2px 0px rgb(81 81 81 / 15%);
						box-sizing: border-box;
						padding: 20px;
						color: #333333;
						li {
							a {
								background: url(~@/assets/ico/jt1.png) left
									center no-repeat;
								background-size: 6px 11px;
								padding-left: 15px;
								display: block;
								font-size: 32px;
								font-weight: 400;
								color: #333333;
								text-align: left;
								line-height: 44px;
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;
							}
						}
					}
				}
			}
		}
	}
	@media screen and (min-width: 751px) {
		.htop{
		height: 70px;
	}
		.case {
			&-tab {
				ul {
					li {
						padding: 22px 26px;
					}
				}
				.case-nav {
					font-size: 16px;
					&.active {
						font-size: 16px;
					}
				}
			}
			&-content {
				display: flex;
				flex-wrap: wrap;
				&-head {
					font-size: 18px;
				}
				&-item {
					width: calc(100% / 2 - 10px);
					margin-right: 20px;
					&:nth-child(2n) {
						margin-right: 0;
					}
					&-cont {
							&-title {
								font-size: 16px;
							}
							&-des {
								line-height: 22px;
								font-size: 16px;
							}
							&-tags {
								span {
									font-size: 14px;
									line-height: 20px;
								}
							}
						}
				}
			}
			&-more {
				width: 144px;
				height: 40px;
				border-radius: 2px;
				border: 1px solid #2b6bff;
				text-align: center;
				margin: 50px auto 0 auto;
				a {
					font-size: 16px;
					font-weight: 400;
					color: #2b6bff;
					line-height: 40px;
				}
			}
		}
		.partner {
			&-cont {
				.img {
					width: calc(100% / 3);
				}
			}
		}
		
	}
	@media screen and (min-width: 870px) {
		.product-nav {
			position: relative;
			z-index: 2;
			margin-top: 0;
			ul {
				box-shadow: 0px 2px 10px 0px rgba(29, 33, 41, 0.1);
				background-color: #ffffff;
				position: absolute;
				left: 50%;
				bottom: -60px;
				transform: translateX(-50%);
				li {
					flex: 1;
					padding: 24px 10px 24px 50px;
					margin-top: 0;
					margin-bottom: 0;
					box-shadow: none;
					width: 33.33%;
					position: absolute;
					box-sizing: border-box;
					bottom: 0;
					a {
						font-size: 14px;
						h4{
							font-size: 18px;
						}
						p {
							min-height: 36px;
						}
					}
					&:nth-child(1) {
						background: #ffffff url(~@/assets/ico/icon-nft.png) 10px
							17px no-repeat;
						background-size: 38px 38px;
					}
					&:nth-child(2) {
						left: 33.33%;
						background: #ffffff
							url(~@/assets/ico/icon-yuanyuzhou.png) 10px 17px
							no-repeat;
						background-size: 38px 38px;
					}
					&:nth-child(3) {
						left: 66.66%;
						background: #ffffff url(~@/assets/ico/icon-kaifa.png)
							10px 17px no-repeat;
						background-size: 38px 38px;
					}
					&:hover {
						a {
							color: #abc4ff;
							.more,
							h4 {
								color: #ffffff;
								background: none;
							}
							.more {
								position: relative;
								opacity: 1;
								margin-top: 27px;
							}
						}
						&:nth-child(1) {
							background: #2b6bff url(~@/assets/ico/icon-nft2.png)
								10px 17px no-repeat;
							background-size: 38px 38px;
						}
						&:nth-child(2) {
							background: #2b6bff
								url(~@/assets/ico/icon-yuanyuzhou2.png) 10px
								17px no-repeat;
							background-size: 38px 38px;
						}
						&:nth-child(3) {
							background: #2b6bff
								url(~@/assets/ico/icon-kaifa2.png) 10px 17px
								no-repeat;
							background-size: 38px 38px;
						}
					}
				}
			}
		}	
		.about-us {
			padding: 40px 0;
			&-header {
				&-title {
					font-size: 24px;
					font-weight: 500;
					color: #1d2129;
				}
				&-des {
					font-size: 16px;
					font-weight: 400;
					color: #323233;
					margin-top: 5px;
				}
			}
			&-content {
				margin-top: 80px;
				&-item {
					width: 20%;
				}
				&-title {
						span {
							font-size: 14px;
							font-weight: 600;
							color: #1d2129;
							&:nth-child(1) {
								font-size: 30px;
							}
						}
					}
			}
		}
		.partner {
			padding: 40px 0 0;
			&-cont {
				margin-top: 40px;
				.img {
					width: 25%;
				}
			}
		}
		.news {
			padding: 40px 0;
			&-content {
				&-head {
					font-size: 18px;
				}
		}
	}
}
	@media screen and (min-width: 1100px) {
		.case {
			&-content {
				&-item {
					width: calc(100% / 3 - 10px);
					margin-right: 15px;
					margin-top: 40px;
					&:hover {
						box-shadow: 0px 2px 10px 0px rgba(29, 33, 41, 0.1);
						.case-content-item-img {
							overflow: hidden;
							img {
								transform: scale(1.2);
							}
						}
					}
					&:nth-child(2n) {
						margin-right: 15px;
					}
					&:nth-child(3n) {
						margin-right: 0px;
					}
					&-cont {
						padding: 20px;
					}
				}
			}
			&-more {
				&:hover {
					background-color: #2b6bff;
					a {
						color: #ffffff;
					}
				}
			}
		}
		.home-more {
			padding: 40px 0;
			&-content {
				margin-top: 40px;
				&-item {
					width: calc(33.33% - 14px);
					margin-right: 16px;
					padding: 24px;
					&-des {
						font-size: 16px;
					}
					&:hover {
						transform: translateY(-16px);
					}
					&:nth-child(2n) {
						margin-right: 16px;
					}
					&:nth-child(3n) {
						margin-right: 0;
					}
					h5 {
						font-size: 16px;
						font-weight: 600;
						color: #1d2129;
						line-height: 22px;
					}
					&-des {
						font-size: 14px;
						font-weight: 400;
						color: #646566;
						line-height: 24px;
						margin-top: 8px;
					}
				}
				
			}
		}
		.partner {
			padding: 40px 0 0;
			&-cont {
				.img {
					&:hover {
						box-shadow: 0px 1px 16px 0px rgba(33, 86, 210, 0.15);
						&::after {
							border: 2px solid #2b6bff;
						}
					}
				}
			}
		}
		.news {
			padding: 40px 0;
			&-content {
				&-cont {
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
					&-item {
						width: 389px;
						.news-hot {
							overflow: hidden;
							img {
								transition: 0.35s;
							}
							&:hover {
								img {
									transform: scale(1.2);
								}
							}
							&-cont {
							background: rgba(#000000, 0.88);
							position: absolute;
							width: 100%;
							left: 0;
							bottom: 0;
							padding: 10px 20px;
							box-sizing: border-box;
							&-title {
								font-size: 14px;
								color: #ffffff;
							}
							&-des {
								font-size: 12px;
								color: #ffffff;
								white-space: nowrap;
								text-overflow: ellipsis;
								overflow: hidden;
							}
						}
						}
						.news-newlist {
							transition: 0.35s;
							&:hover {
								transform: scale(0.98);
								.news-newlist-top {
									.news-newlist-top-right {
										.news-newlist-top-right-title {
											color: #2b6bff;
										}
									}
								}
							}
							&-top {
							display: flex;
							&-left {
								margin-right: 15px;
								text-align: center;
								&-day {
									font-size: 24px;
									font-weight: 600;
									color: #333333;
								}
								&-year {
									font-size: 14px;
									font-weight: 400;
									color: #999999;
									white-space: nowrap;
									margin-top: 14px;
									position: relative;
									&::after {
										content: "";
										width: 20px;
										height: 2px;
										background-color: #333333;
										position: absolute;
										left: 50%;
										top: -10px;
										transform: translateX(-50%);
									}
								}
							}
							&-right {
								&-title {
									font-size: 16px;
									color: #333333;
									// white-space: nowrap;
									// text-overflow: ellipsis;
									// overflow: hidden;
								}
								&-des {
									margin-top: 10px;
									font-size: 14px;
									color: #666666;
									overflow: hidden;
									text-overflow: ellipsis;
									display: -webkit-box;
									-webkit-line-clamp: 2;
									-webkit-box-orient: vertical;
								}
							}
						}
						&-tags {
							font-size: 14px;
							font-weight: 400;
							color: #999999;
							margin-top: 10px;
							span {
								margin-right: 5px;
								margin-bottom: 5px;
							}
						}
						}
						.fqa {
							li {
								a {
									font-size: 16px;
								}
							}
							li:hover {
								a {
									color: #2b6bff;
									font-size: 16px;
								}
							}
						}
						&:nth-child(2) {
							width: calc(100% - 400px);
						}
						&:nth-child(3) {
							width: 100%;
						}
					}
				}
			}
		}
	}
	@media screen and (min-width: 1300px) {
		.product-nav {
			ul {
				li {
					padding: 24px 50px 24px 90px;
					a {
						font-size: 14px;
					}
					&:nth-child(1) {
						background: #ffffff url(~@/assets/ico/icon-nft.png) 50px
							17px no-repeat;
						background-size: 38px 38px;
					}
					&:nth-child(2) {
						background: #ffffff
							url(~@/assets/ico/icon-yuanyuzhou.png) 50px 17px
							no-repeat;
						background-size: 38px 38px;
					}
					&:nth-child(3) {
						background: #ffffff url(~@/assets/ico/icon-kaifa.png)
							50px 17px no-repeat;
						background-size: 38px 38px;
					}
					&:hover {
						&:nth-child(1) {
							background: #2b6bff url(~@/assets/ico/icon-nft2.png)
								50px 17px no-repeat;
							background-size: 38px 38px;
						}
						&:nth-child(2) {
							background: #2b6bff
								url(~@/assets/ico/icon-yuanyuzhou2.png) 50px
								17px no-repeat;
							background-size: 38px 38px;
						}
						&:nth-child(3) {
							background: #2b6bff
								url(~@/assets/ico/icon-kaifa2.png) 50px 17px
								no-repeat;
							background-size: 38px 38px;
						}
					}
				}
			}
		}
		.Gtitle {
			font-size: 32px;
			font-weight: 500;
			color: #1d2129;
			line-height: 45px;
			margin:60px auto;
			margin-bottom: 63px;
		}
		.case {
			&-content {
				&-item {
					width: calc(100% / 3 - 16px);
					margin-right: 24px;
					margin-top: 60px;
					&:nth-child(2n) {
						margin-right: 24px;
					}
					&:nth-child(3n) {
						margin-right: 0px;
					}
					&-cont {
						padding: 30px 30px 40px 30px;
					}
				}
			}
		}
		.home-more {
			padding: 80px 0;
			&-content {
				margin-top: 60px;
				&-item {
					width: calc(25% - 18px);
					margin-right: 24px;
					&:nth-child(2n) {
						margin-right: 24px;
					}
					&:nth-child(3n) {
						margin-right: 24px;
					}
					&:nth-child(4n) {
						margin-right: 0;
					}
				}
			}
		}
		.about-us {
			padding: 90px 0 100px 0;
			&-header {
				&-title {
					font-size: 32px;
					font-weight: 600;
				}
				&-des {
					margin-top: 14px;
				}
			}
			&-content {
				// margin-top: 135px;
				&-item {
					&-title {
						span {
							font-size: 16px;
							&:nth-child(1) {
								font-size: 40px;
							}
						}
					}
				}
			}
		}
		.partner {
			padding: 80px 0 0;
		}
		.news {
			padding: 80px 0;
			&-content {
				&-head {
					&-right {
						display: block;
						width: 389px;
					}
				}
				&-cont {
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
					&-item {
						width: 389px;
						.news-newlist {
							padding: 10px 21px;
							height: 127px;
							box-sizing: border-box;
							&-top {
								&-right {
									width: calc(100% - 60px);
									&-title {
										font-size: 16px;
										overflow: hidden;
										white-space: nowrap;
										text-overflow: ellipsis;
									}
									&-des {
										font-size: 12px;
										margin-top: 5px;
									}
								}
							}
							&-tags {
								margin-top: 5px;
							}
						}
						.news-hot {
							img {
								height: 264px;
							}
						}
						&:nth-child(2) {
							width: 389px;
						}
						&:nth-child(3) {
							width: 389px;
							.news-content-head {
								display: none;
							}
						}
						.fqa {
							li {
								a {
									line-height: 45px;
								}
							}
						}
					}
				}
			}
		}
		
	}
		.ish5{
			.banner {
			padding-top: 140px;
			.swiper-slide{
				display: flex;
				align-items: center;
				justify-content: center;
			}
			img {
				width: 100%;
			}
		}
		}
</style>
